import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'app/arquitetura/shared/guards/security/auth.guard';
import {DadosUsuarioGuard} from 'app/arquitetura/shared/guards/security/dados-usuario.guard';
import { AguardarAtualizacaoPaginaComponent } from './arquitetura/shared/templates/aguardar-atualizacao-pagina/aguardar-atualizacao-pagina.component';


const appRoutes: Routes = [
	{
		path: 'home',
    loadChildren: () => import('app/subsidiaria/subsidiaria.module')
		.then(m => m.SubsidiariaModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'subsidiaria',
		loadChildren: () => import('app/subsidiaria/subsidiaria.module')
			.then(m => m.SubsidiariaModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'administracao',
		loadChildren: () => import('app/administracao/administracao.module')
			.then(m => m.AdministracaoModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'ocorrencia',
		loadChildren: () => import('app/ocorrencia/ocorrencia.module')
			.then(m => m.OcorrenciaModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'relatorios',
		loadChildren: () => import('app/relatorios/relatorios.module')
			.then(m => m.RelatoriosModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'tratar-ocorrencia',
		loadChildren: () => import('app/tratar-ocorrencia/tratar-ocorrencia.module')
			.then(m => m.TratarOcorrenciaModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	{
		path: 'ura',
		loadChildren: () => import('app/ura/ura.module')
			.then(m => m.UraModule),
		canActivate: [AuthGuard, DadosUsuarioGuard],
		canActivateChild: [AuthGuard, DadosUsuarioGuard],
		canLoad: [AuthGuard]
	},
	/*CAST_MAKER_ROTA_MODULO*/
	{ path: '', pathMatch: 'full', redirectTo: '/home' },
	{ path: '**', component: AguardarAtualizacaoPaginaComponent, canActivate: [AuthGuard] }
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {
	constructor(private router: Router) {
	}
}
