import {NomeAcaoPipe} from './nome-acao';
import {NgModule} from '@angular/core';
import {CpfPipe} from './cpf.pipe';
import {CampoVazioPipe} from './campo-vazio.pipe';
import {FlagSimNaoPipe} from './flag-sim-nao.pipe';
import {MesPipe} from './mes.pipe';
import {OrderBy} from './orderby.pipe';
import {AtivoPipe} from './ativo.pipe';
import {ModuloPipe} from './modulo.pipe';
import {TipoTemplatePipe} from './tipo-template.pipe';
import {TipoItemTemplatePipe} from './tipo-item-template.pipe';
import {TelefonePipe} from './telefone.pipe';
import {LeftPadPipe} from './left-pad.pipe';
import {JoinOpcoesPipe} from './join-opcoes.pipe';
import {TipoMovimentacaoPipe} from './tipo-movimentacao.pipe';
import {SituacaoSolicitacaoUnidadePipe} from './situacao-solicitacao-unidade.pipe';
import {SituacaoSolicitacaoOcorrenciaInternaPipe} from './situacao-solicitacao-ocorrencia-interna.pipe';
import {NomeFontePipe} from './nome-fonte.pipe';
import {TipoTelefonePipe} from './tipo-telefone.pipe';
import {TipoEstiloFontePipe} from './tipo-estilo-fonte.pipe';
import {CpfCnpjPipe} from './cpfCnpj.pipe';
import {IdentificadorOcorrenciaPipe} from './identificador-ocorrencia.pipe';
import {TipoPrazoPipe} from './tipo-prazo.pipe';
import {AcaoAuditoriaPipe} from './acao-auditoria.pipe';
import {TipoParametroConsolePipe} from './tipo-parametro-console.pipe';
import {CorrespondentePipe} from './correspondente.pipe';
import {NewlinePipe} from "./new-line.pipe";
import {MatriculaPipe} from './matricula.pipe';
import { RemoverTagsHtmlPipe } from './remover-tags-html.pipe';


@NgModule({
  imports: [],
  declarations: [
    CpfPipe,
    CampoVazioPipe,
    FlagSimNaoPipe,
    MesPipe,
    OrderBy,
    AtivoPipe,
    ModuloPipe,
    TipoTemplatePipe,
    TipoItemTemplatePipe,
    JoinOpcoesPipe,
    TelefonePipe,
    TipoMovimentacaoPipe,
    SituacaoSolicitacaoUnidadePipe,
    SituacaoSolicitacaoOcorrenciaInternaPipe,
    NomeFontePipe,
    NomeAcaoPipe,
    TipoTelefonePipe,
    TipoEstiloFontePipe,
    CpfCnpjPipe,
    IdentificadorOcorrenciaPipe,
    TipoPrazoPipe,
    AcaoAuditoriaPipe,
    TipoParametroConsolePipe,
    CorrespondentePipe,
    LeftPadPipe,
    NewlinePipe,
    MatriculaPipe,
    RemoverTagsHtmlPipe,
  ],
  exports: [
    CpfPipe,
    CampoVazioPipe,
    FlagSimNaoPipe,
    OrderBy,
    AtivoPipe,
    ModuloPipe,
    TipoTemplatePipe,
    TipoItemTemplatePipe,
    JoinOpcoesPipe,
    NomeAcaoPipe,
    TelefonePipe,
    TipoMovimentacaoPipe,
    SituacaoSolicitacaoUnidadePipe,
    SituacaoSolicitacaoOcorrenciaInternaPipe,
    NomeFontePipe,
    TipoTelefonePipe,
    TipoEstiloFontePipe,
    CpfCnpjPipe,
    IdentificadorOcorrenciaPipe,
    TipoPrazoPipe,
    AcaoAuditoriaPipe,
    TipoParametroConsolePipe,
    CorrespondentePipe,
    LeftPadPipe,
    NewlinePipe,
    MatriculaPipe,
    RemoverTagsHtmlPipe
  ],
})
export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
