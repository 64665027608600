import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'acaoAuditoria' })
export class AcaoAuditoriaPipe implements PipeTransform {
	transform(value: string) {
		if (value === 'U') {
			return 'Alteração';
		}
		if (value === 'I') {
			return 'Inclusão';
		}
		if (value === 'D') {
			return 'Exclusão';
		}
		if (value === 'C') {
			return 'Cancelamento';
		}
		return value;
	}
}