import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs/Rx';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SolicitacaoUnidade} from 'app/shared/models/solicitacao/solicitacao-unidade';

@Injectable()
export class SolicitacaoUnidadeService extends CrudHttpClientService<SolicitacaoUnidade> {
  constructor(protected http: HttpClient, private messageService: MessageService) {
    super('solicitacao/solicitacao-unidade', http);
  }

  consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  contestarPenalidade(solicitacaoUnidade: SolicitacaoUnidade) {
    return this.http.post(this.url + '/contestar-penalidade', solicitacaoUnidade, this.options());
  }

  avaliarContestacao(solicitacao: SolicitacaoUnidade, idPenalidade: number): Observable<any> {
    return this.http.post(this.url + '/avaliar-contestacao' + '/' + idPenalidade, solicitacao, this.options());
  }

  consultarPaginadoSolicitacaoUnidade(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado-solicitacao-unidade', filtro, this.options());
  }

  consultarHistoricoSolicitacao(filtro: FiltroPaginado): Observable<any>{
    return this.http.post(this.url + '/consultar-historico-solicitacao', filtro, this.options());
  }

  excluir(solicitacao: SolicitacaoUnidade) {
    return this.http.put(this.url + '/excluir', solicitacao, this.options());
  }

  public redistribuirSolicitacoes(idsSolicitacoes: number[], idEquipe: number, numeroMatricula: number) {
    const paramMap = {'idsSolicitacoes': idsSolicitacoes, 'numeroMatricula': numeroMatricula};
    return this.http.post(`${this.url}/redistribuir`, paramMap, this.options());
  }
}
