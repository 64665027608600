import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Template} from 'app/shared/models/administracao/parametrizacao/template';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {Observable, Subject} from 'rxjs';


@Injectable()
export class TemplateService extends CrudHttpClientService<Template> {

  private isTemplateTarefa$ = new Subject<any>();

  emitTemplateTarefa(isTrue: boolean) {
    setTimeout(() =>this.isTemplateTarefa$.next(isTrue), 500);
  }

  getEmitTemplateTarefa(): Observable<any> {
    return this.isTemplateTarefa$.asObservable();
  }

  unsubscribe() {
    this.isTemplateTarefa$.unsubscribe();
  }

    constructor(
        protected http: HttpClient,
        protected messageService: MessageService
    ) {
        super('administracao/parametrizacao/template', http);
    }

    public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
        return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
    }

    public incluir(template: Template): Observable<Template> {
        return this.post(template);
    }

    public templateCadastro(template: Template): Observable<Template> {
        return this.http.post<Template>(this.url + '/template-cadastro', template, this.options());
    }

    public templateConsulta(id: number) {
        return this.http.get(this.url + `/template-consulta/${id}`, this.options());
    }

    public incluirNovasPerguntas(template: Template): Observable<Template> {
        return this.http.post<Template>(this.url + '/incluir-novas-perguntas', template, this.options());
    }

    public alterar(template: Template): Observable<Template> {
        return this.put(template);
    }

    consultarPorTipo(tipo: string) {
        return this.http.get(`${this.url}/tipo/${tipo}`);
    }

    public alterarSituacao(template: Template): Observable<any> {
        return this.http.put(this.url + '/ativar-inativar', template, this.options());
    }

    consultarPorTipoEClassificacao(tipo: string, idClassificacao: number, idTipoOcorrencia: number) {
        return this.http.get(this.url + `/tipo/${tipo}/classificacao/${idClassificacao}/tipoOcorrencia/${idTipoOcorrencia}`, this.options());
    }

    searchAutocomplete(value: string, idTipoOcorrencia: number): Observable<any> {
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set('value', value);
        httpParams = httpParams.set('idTipoOcorrencia', idTipoOcorrencia.toString());
        return this.http.get(this.url + '/search-autocomplete/', this.options({params: httpParams}));
    }

    contador(): Observable<number> {
        return this.http.get<number>(this.url + "/contador", this.options());
    }

    public templateCadastroOcorrencia(template: Template): Observable<Template> {
        return this.http.post<Template>(this.url + '/template-ocorrencia', template, this.options());
    }
}
