import {EnumTipoOcorrencia} from './../../../../shared/enums/EnumTipoOcorrencia';
import {MenuItem} from 'primeng/primeng';
import {MenuLateralComponent} from '../menu-lateral/menu-lateral.component';
import {PerfilModulo} from 'app/shared/models/perfil-modulo';
import {SessaoService} from '../../services/seguranca/sessao.service';

export class ItensMenuSac {

  constructor(private menuParent: MenuLateralComponent, private sessaoService: SessaoService) {
  }

  private recuperaPerfis(): string[] {
    return this.sessaoService.getUsuario().perfisParaSelecao;
  }

  private possuiPermissaoSAC(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSAC(perfil));
  }

  private verificarPerfilSAC(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_AUDITORIA.value)
        || perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_UNID_CAIXA.value)
        || perfil.match(PerfilModulo.SAC_OPER_N1.value)
        || perfil.match(PerfilModulo.SAC_OPER_N2.value)
        || perfil.match(PerfilModulo.SAC_ESTAGIARIO.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoSACSemN1N2(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACSemN1N2(perfil));
  }

  private verificarPerfilSACSemN1N2(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_AUDITORIA.value)
        || perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_UNID_CAIXA.value)
        || perfil.match(PerfilModulo.SAC_ESTAGIARIO.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoSACSemN1(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACSemN1(perfil));
  }

  private verificarPerfilSACSemN1(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_AUDITORIA.value)
        || perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_OPER_N2.value)
        || perfil.match(PerfilModulo.SAC_UNID_CAIXA.value)
        || perfil.match(PerfilModulo.SAC_ESTAGIARIO.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoADMAuditoriaSAC(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilADMAuditoriaSAC(perfil));
  }

  private verificarPerfilADMAuditoriaSAC(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_AUDITORIA.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoADMSAC(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilADMSAC(perfil));
  }

  private verificarPerfilADMSAC(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)){
      return true
    }
    return false;
  }

  private verificarPerfilSACGestaoAvaliacao(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoSACGestaoAvaliacao(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACGestaoAvaliacao(perfil));
  }

  private verificarPerfilSACOrientacaoPedido(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_AUDITORIA.value )){
      return true
    }
    return false;
  }

  private possuiPermissaoSACOrientacaoPedido(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACOrientacaoPedido(perfil));
  }

  private verificarPerfilSACOperador(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoSACOperador(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACOperador(perfil));
  }

  private verificarPerfilSACPainelAvaliacao(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_GEST_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_OPER_UNID_ATEND.value)
        || perfil.match(PerfilModulo.SAC_OPER_N2.value)){
      return true
    }
    return false;
  }
  private verificarPerfilSACN1N2ADM(perfil: String): boolean{
    if(perfil.match(PerfilModulo.SAC_GEST_ADM_SISTEMA.value)
        || perfil.match(PerfilModulo.SAC_OPER_N1.value)
        || perfil.match(PerfilModulo.SAC_OPER_N2.value)){
      return true
    }
    return false;
  }

  private possuiPermissaoSACPainelAvaliacao(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACPainelAvaliacao(perfil));
  }

  private possuiPermissaoSACN1N2ADM(): boolean{
    const perfis = this.recuperaPerfis();
    return perfis.some( perfil => this.verificarPerfilSACN1N2ADM(perfil));
  }

  public get itens(): MenuItem[] {
    return [
      // SAC
      {
        label: "SAC",
        icon: "fa fa-cogs",
        visible: this.possuiPermissaoSAC(),
        items: [
          {
            label: "Parametrização",
            icon: "fa fa-angle-double-right",
            visible: this.possuiPermissaoSACSemN1(),
            items: [
              {
                label: "Origem",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/origem/",
                visible: this.possuiPermissaoADMAuditoriaSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Natureza",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/natureza/",
                visible: this.possuiPermissaoADMAuditoriaSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Produto",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/produto/",
                visible: this.possuiPermissaoADMAuditoriaSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Problema",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/problema/",
                visible: this.possuiPermissaoADMAuditoriaSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Categorização",
                icon: "fa fa-angle-double-right",
                routerLink:
                  "/administracao/parametrizacao/categorizacao-ocorrencia-sac/",
                visible: this.possuiPermissaoADMAuditoriaSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Canal",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/canal/",
                visible: this.possuiPermissaoADMAuditoriaSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Orientação Atendimento",
                icon: "fa fa-angle-double-right",
                routerLink:
                  "/administracao/parametrizacao/orientacao-atendimento/",
                visible: this.possuiPermissaoSACOrientacaoPedido(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Pedido do Cliente",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/pedido-cliente/",
                visible: this.possuiPermissaoSACOrientacaoPedido(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Grupo Responsável por Tratamento",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/grupo-tratamento-sac/",
                visible: this.possuiPermissaoSACGestaoAvaliacao(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Equipe Responsável",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/equipe-responsavel/",
                visible: this.possuiPermissaoSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Parâmetro Avaliação",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/parametro-avaliacao-sac/",
                visible: this.possuiPermissaoADMSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

                }
              },
              {
                label: "Penalidade",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/penalidade-sac-ouv/",
                visible: this.possuiPermissaoADMSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

                }
              },
              {
                label: "SMS",
                icon: "fa fa-angle-double-right",
                routerLink:
                  "/administracao/parametrizacao/sms/",
                visible: this.possuiPermissaoSACOperador(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

                }
              },
              {
                label: "Unidade Bloqueada",
                icon: "fa fa-angle-double-right",
                routerLink: "/administracao/parametrizacao/unidade-bloqueada-so/",
                visible: this.possuiPermissaoADMSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
              {
                label: "Ajuda",
                icon: "fa  fa-question-circle",
                routerLink: "/subsidiaria/ajuda",
                visible: this.possuiPermissaoADMSAC(),
                command: event => {
                  this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
                }
              },
            ]
          },
          {
            label: "Registra Ocorrência",
            icon: "fa fa-angle-double-right",
            routerLink: "/ocorrencia/ocorrencia-sac",
            visible: this.possuiPermissaoSACSemN1(),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);

            }
          },
          {
            label: "Minhas Ocorrências Registradas",
            icon: "fa fa-angle-double-right",
            routerLink: "/ocorrencia/ocorrencia-sac-minhas-ocorrencias-listar",
            visible: this.possuiPermissaoSACN1N2ADM(),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Painel de Controle",
            icon: "fa fa-angle-double-right",
            routerLink: "/tratar-ocorrencia/tratar-ocorrencia-sac/painel-controle",
            visible: this.possuiPermissaoSAC(),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Painel Avaliação",
            icon: "fa fa-angle-double-right",
            routerLink: "/tratar-ocorrencia/tratar-ocorrencia-sac/painel-avaliacao",
            visible: this.possuiPermissaoSACPainelAvaliacao(),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Gestão da Avaliação",
            icon: "fa fa-angle-double-right",
            routerLink: "/tratar-ocorrencia/tratar-ocorrencia-sac/gestao-avaliacao",
            visible: this.possuiPermissaoSACGestaoAvaliacao(),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          },
          {
            label: "Pré-Ocorrencia",
            icon: "fa fa-angle-double-right",
            routerLink: "/ocorrencia/pre-ocorrencia",
            visible: this.possuiPermissaoSACSemN1N2(),
            command: event => {
              this.menuParent.itemMenuClick(EnumTipoOcorrencia.SAC);
            }
          }
        ]
      },
    ];
  }

}

