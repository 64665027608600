import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import "reflect-metadata";

import {environment} from 'environments/environment';
import {AppModule} from './app/app.module';
import {HttpClientService} from 'app/arquitetura/shared/services/http-client.service';
import {Storage} from 'app/arquitetura/shared/storage/storage';
import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';

if (environment.server) {
	enableProdMode();
}

const NOME_SISTEMA: string = 'SISOU';
HttpClientService.setNomeSistema(NOME_SISTEMA);
Storage.setNomeSistema(NOME_SISTEMA);

SessaoService.init()
.then((result: number) => {
	switch (result) {
		case 0: { // SUCESSO
			platformBrowserDynamic().bootstrapModule(AppModule);
			break;
		}
		case 1: { // ERRO na chamada de Consulta das Configurações de Segurança no Back-end
			alert('[ERRO01] Servidor indisponível - Tente mais tarde.');
			console.error('Ocorreu algum erro ao Consultar as Configurações de Segurança do back-end.');
			console.error('Em ambiente de desenvolvimento, no browser Chrome, eventualmente é ' +
				'necessário confirmar a autenticidade do certificado local de desenvolvimento ' +
				'para o browser liberar o acesso à url. Faça isso numa aba a parte, invocando ' +
				'a URL que gerou o erro.');
			break;
		}
		case 2: { // ERRO na chamada de Integração com o AIM Keycloak
			alert('ERRO na chamada de Integração com o AIM Keycloak.');
			window.location.reload();
			break;
		}
		case 3: { // ERRO na chamada de Consulta do Usuário Logado no Back-end
			console.error('Ocorreu algum erro ao Consultar os dados do Usuário Logado do back-end.');
			alert('[ERRO03] Servidor indisponível - Tente mais tarde.');
			break;
		}
		case 4: { // ERRO de 401, 403 na chamada de Consulta do Usuário Logado no Back-end
			console.error('ERRO de 401, 403 na chamada de Consulta do Usuário Logado no Back-end');
			alert('[ERRO04] Você não possui perfil para acessar o resolve.caixa. Favor solicitar o acesso através do acessologico.caixa');
			break;
		}
	}
})
.catch((e) => console.error('Ocorreu algum erro ao iniciar a aplicação.' + e));
//platformBrowserDynamic().bootstrapModule(AppModule);
