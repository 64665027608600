import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectItemModel} from 'app/shared/models/select-item';
import {SessaoService} from 'app/arquitetura/shared/services/seguranca/sessao.service';
import {UsuarioService} from 'app/arquitetura/shared/services/cadastrobasico/usuario.service';
import {KeycloakService} from 'app/arquitetura/shared/services/seguranca/keycloak.service';
import {Usuario} from 'app/arquitetura/shared/models/cadastrobasico/usuario';
import {Router} from '@angular/router';
import {CabecalhoService} from 'app/arquitetura/shared/services/cabecalho.service';
import {Base} from '../base';
import {MessageService} from "app/shared/components/messages/message.service";
import {PerfilModulo} from "app/shared/models/perfil-modulo";

@Component({
    selector: 'app-modal-perfis',
    templateUrl: './modal-perfis.component.html',
    styleUrls: ['./modal-perfis.component.scss']
})
export class ModalPerfisComponent extends Base implements OnInit {
    formulario: FormGroup;
    displayModal: boolean;
    perfis: SelectItemModel[];
    somentePerfil: SelectItemModel[];
    @Input("botaoPerfil") botaoPerfil: boolean = false;
    @Output() emit = new EventEmitter();

    constructor(
        protected messageService: MessageService,
        private formBuilder: FormBuilder,
        private router: Router,
        private sessaoService: SessaoService,
        private cabelhoService: CabecalhoService
    ) {
        super(messageService);
    }

    ngOnInit() {
        this.displayModal = false;
        this.createForm();
        this.carregarListaPerfis();
    }

    createForm() {
        this.formulario = this.formBuilder.group({
            perfil: [null, Validators.required]
        });
    }

    carregarListaPerfis() {
        this.perfis = [];
        this.somentePerfil = [];

        let opcaoSelecione = new SelectItemModel();
        opcaoSelecione.label = "Selecione";
        this.perfis.unshift(opcaoSelecione);

        if (SessaoService.usuario.perfisParaSelecao) {
            SessaoService.usuario.perfisParaSelecao.forEach(perfil => {
                let opcao = new SelectItemModel();
                opcao.label = perfil;
                opcao.value = perfil;
                this.perfis.push(opcao);
                this.somentePerfil.push(opcao);
            });
        }

        this.acessar();
    }

    acessar(): void {
        if (this.formulario.valid) {
            UsuarioService.consultarPorLogin(KeycloakService.getUsername())
                .then((usuario: Usuario) => {
                    SessaoService.usuario = usuario;

                    // Atualiza Sessão
                    this.sessaoService.atualizarPerfilSessao();

                    // Fecha Modal
                    this.displayModal = false;

                    // Atualiza Cabecalho
                    this.cabelhoService.emiteReloadCabecalho();

                    // Atualiza Menu
                    this.emit.emit();
                });
        } else {
            this.forceValidateAllFormFields(this.formulario);
        }
    }

    acessarSemRedirect(): void {
        if (this.formulario.valid) {
            UsuarioService.consultarPorLogin(KeycloakService.getUsername())
                .then((usuario: Usuario) => {
                    SessaoService.usuario = usuario;

                    // Atualiza Sessão
                    this.sessaoService.atualizarPerfilSessao();

                    // Atualiza Cabecalho
                    this.cabelhoService.emiteReloadCabecalho();

                    // Atualiza Menu
                    this.emit.emit();

                    // Fecha Modal
                    this.displayModal = false;

                    this.definirHomePerfil()
                });
        } else {
            this.forceValidateAllFormFields(this.formulario);
        }
    }

    definirHomePerfil() {
        const perfisUsuario = this.sessaoService.getUsuario().perfis;
        if(perfisUsuario.length > 1) {
            this.router.navigate(['/home']);
        } else {
          switch (perfisUsuario[0]) {
              case PerfilModulo.OUV_SUPERVISOR.value:
                  this.router.navigate(['/ocorrencia/ocorrencia-ouvidoria/supervisor']);
                  break;
              case PerfilModulo.OUV_OPERADOR.value:
                  this.router.navigate(['/ocorrencia/ocorrencia-ouvidoria']);
                  break;
              case PerfilModulo.OUV_CURADORIA.value:
                  this.router.navigate(['/home']);
                  break;
              case PerfilModulo.OUV_GESTORSISTEMA.value:
                  this.router.navigate(['/home']);
                  break;
              default:
                   this.router.navigate(['/home']);
                   break;
          }
        }
    }

    fechar(event: any) {
        // Atualiza Cabecalho
        this.cabelhoService.emiteReloadCabecalho();

        // Atualiza Menu
        this.emit.emit();

        // Fecha Modal
        this.displayModal = false;
    }

    private verificarPerfilExterno(perfilSelecionado: string) {
        if (perfilSelecionado != null) {
            if (perfilSelecionado.includes(PerfilModulo.SOU_TERCEIRIZADAOP.value)) {
                return PerfilModulo.SOU_TERCEIRIZADAOP.value;
            }
            if (perfilSelecionado.includes(PerfilModulo.SOU_TERCEIRIZADAGE.value)) {
                return PerfilModulo.SOU_TERCEIRIZADAGE.value;
            }
        }
        return perfilSelecionado;
    }
}
