import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {
    ContestacaoHistorico,
    Penalidade,
    PenalidadeTarefa,
    PenalidadeTarefaFK,
    SolicitacaoPenalidade
} from 'app/shared/models/ocorrencia/penalidade-tarefa';
import {Observable} from 'rxjs';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {SelectItemModel} from "../../models/select-item";

@Injectable()
export class PenalidadeTarefaService extends CrudHttpClientService<SolicitacaoPenalidade> {

  constructor(protected http: HttpClient) {
    super('ocorrencia-externa/penalidade-tarefa', http);
  }

  public consultarPaginado(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-paginado', filtro, this.options());
  }

  public getPenalidadeTarefa(id: PenalidadeTarefaFK): Observable<PenalidadeTarefa> {
    return this.http.get<PenalidadeTarefa>(`${this.url}/` + id.idSolicitacaoPenalidade + '/' + id.idPenalidade);
  }


  public getContestacao(contestacao:ContestacaoHistorico): Observable<ContestacaoHistorico> {
    return this.http.post<ContestacaoHistorico>(`${this.url}/consultar-solicitacao/`, contestacao, this.options());
  }

  public obterPorTipoOcorrencia(idTipoOcorrencia: number): Observable<Penalidade[]> {
    return this.http.get<Penalidade[]>(this.url + '/tipoOcorrencia/' + idTipoOcorrencia);
  }

  public obterTodosSacOuvidoria(idsTipoOcorrencia: number[]): Observable<SelectItemModel[]> {
    return this.http.post<SelectItemModel[]>(this.url + '/sac-ouvidoria/', idsTipoOcorrencia, this.options());
  }

  public consultarRelatorioPenalidade(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-relatorio-paginado', filtro, this.options());
  }

  gerarRelatorioPainelContestacao(filtro: FiltroPaginado) {
    return this.http.post( this.url + '/gerar-relatorio-painel-contestacao', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  public consultarRelatorioSinteticoPenalidade(filtro: FiltroPaginado): Observable<any> {
    return this.http.post(this.url + '/consultar-relatorio-sintetico-paginado', filtro, this.options());
  }

  public gerarRelatorioSinteticoPenalidade(filtro: FiltroPaginado){
    return this.http.post( this.url + '/gerar-relatorio-sintetico-penalidade', filtro, this.options({observe: 'response' as 'body', responseType: 'arraybuffer'}));
  }

  public verificaSePodeAvaliarContestacaoPenalidade(idGrupo: number): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/verifica-acoes-contestacoes-penalidades/' + idGrupo);
  }

  public redistribuirSolicitacoes(ids: number[], idEquipe: number, idUsuario: number): Observable<any> {
    //ALTERAR PARA O ENDPOINT CORRETO
    return this.http.post(this.url + '/consultar-paginado', this.options());
  }

  public getTiposPenalidade(): Observable<any> {
    return this.http.get<SelectItemModel>(this.url + '/tipos-penalidade');
  }
}
