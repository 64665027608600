import { PerfilModulo } from 'app/shared/models/perfil-modulo';
import {
    AfterContentChecked,
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

import {LoadingModalComponent} from 'app/shared/components/loading/loading-modal.component';
import {MessageService} from 'app/shared/components/messages/message.service';
import {EnumTipoOcorrencia} from 'app/shared/enums/EnumTipoOcorrencia';
import {SessionsEnum} from 'app/shared/enums/sessions.enum';
import {SituacaoOcorrenciaEnum} from 'app/shared/enums/situacao-ocorrencia-enum';
import {Equipe} from 'app/shared/models/administracao/parametrizacao/equipe';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';
import {OcorrenciaExterna} from 'app/shared/models/ocorrencia/ocorrencia-externa';
import {LeftPadPipe} from 'app/shared/pipes/left-pad.pipe';
import {EquipeService} from 'app/shared/services/administracao/parametrizacao/equipe.service';
import {EquipeConsoleOcorrenciaService} from 'app/shared/services/ocorrencia/equipe-console-ocorrencia.service';
import {OcorrenciaExternaService} from 'app/shared/services/ocorrencia/ocorrencia-externa.service';
import {OcorrenciaInternaService} from 'app/shared/services/ocorrencia/ocorrencia-interna.service';
import {OcorrenciaOuvidoriaService} from 'app/shared/services/ocorrencia/ocorrencia-ouvidoria.service';
import {SolicitanteService} from 'app/shared/services/ocorrencia/solicitante.service';
import {BackgroundModuloUtils} from 'app/shared/util/background-modulo-utils';

import {BasePageable} from 'app/shared/components/base-pageable';
import {MenuItem} from 'primeng/primeng';
import {Subject} from 'rxjs/Rx';
import {filter} from 'rxjs/operators';
import {EquipeUsuario} from '../../models/cadastrobasico/equipe-usuario';
import {CabecalhoService} from '../../services/cabecalho.service';
import {MenuLateralService} from '../../services/menu-lateral.service';
import {SessaoService} from '../../services/seguranca/sessao.service';
import {EquipeUsuarioStorage} from '../../storage/equipe-usuario-storage';
import {PerfilUtils} from '../../util/perfil-utils';
import { TamanhoEnum } from 'app/shared/enums/tamanho-enum';
import { WebUtil } from '../../util/web-util';
import {EquipeTratamentoService} from "../../../../shared/services/administracao/parametrizacao/equipe-tratamento.service";
import {VerificacaoEquipeAcoes} from "../../../../shared/models/administracao/parametrizacao/equipe-tratamento/verificacao-equipe-acoes.dto";
import {Observable} from "rxjs";


declare let jQuery: any;

@Component({
  selector: "app-cabecalho-padrao",
  templateUrl: "./cabecalho-padrao.component.html",
  styleUrls: ["./cabecalho-padrao.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CabecalhoPadraoComponent
  extends BasePageable
  implements OnInit, OnDestroy, AfterContentChecked, AfterViewChecked, OnChanges
{
  displayModal: boolean = false;
  interno = '#INTERNO.TODOS';
  @Input() versao: string;

  @ViewChild("modalLoading") carregando: LoadingModalComponent;

  public mensagemContinuidadeUso: string;
  valorPesquisa: string;
  equipes: Equipe[];
  equipe: Equipe;
  equipeUsuarioStorage: EquipeUsuarioStorage;
  itemsBusca: MenuItem[];
  isApresentaModalPerfis: boolean = false;

  // MODAL HISTORICO SOLICITANTE
  itens: any[] = [];
  nomeCliente: string;
  cpfCnpj: number;
  cpfCompleto: string;
  isEquipeOuvEditavel = false;
  perfisUsuario = this.sessaoService.getUsuario().perfis;
  regexMatricula = new RegExp("^[CEP]([0-9]{6})$");
  regexMatriculaMinuscula = new RegExp("^[cep]([0-9]{6})$");
  placeholderBuscaRapida = "Insira o nº da ocorrência, CPF ou CNPJ";

  private rotasConsoleOuv = [
    "/ocorrencia/ocorrencia-ouvidoria",
    "/ocorrencia/ocorrencia-ouvidoria/supervisor",
  ];

  @Input()
  public mask: Subject<string> = new Subject();

  cols: any[] = [
    {
      field: "identificacaoOcorrencia",
      header: "Ocorrência",
      width: "20%",
      ordenacao: true,
    },
    { field: "tipoOcorrencia", header: "Tipo", width: "15%", ordenacao: true },
    {
      field: "situacaoOcorrencia",
      header: "Situação",
      width: "15%",
      ordenacao: true,
    },
    { field: "nota", header: "Nota", width: "10%", ordenacao: true },
    {
      field: "dataInicioOcorrenciaExterna",
      header: "Data de Abertura",
      width: "18%",
      ordenacao: true,
    },
    {
      field: "dataFinalOcorrenciaExterna",
      header: "Prazo Final",
      width: "19%",
      ordenacao: true,
    },
    { field: "origem", header: "Origem", width: "15%", ordenacao: true },
    { field: "natureza", header: "Natureza", width: "19%", ordenacao: true },
    {
      field: "categorizacao",
      header: "Categorização",
      width: "20%",
      ordenacao: false,
    },
  ];

  constructor(
    protected sessaoService: SessaoService,
    protected equipeService: EquipeService,
    protected messageService: MessageService,
    protected ocorrenciaOuvidoriaService: OcorrenciaOuvidoriaService,
    protected ocorrenciaInternaService: OcorrenciaInternaService,
    protected router: Router,
    private route: ActivatedRoute,
    protected ocorrenciaExternaService: OcorrenciaExternaService,
    protected solicitanteService: SolicitanteService,
    protected equipeConsoleService: EquipeConsoleOcorrenciaService,
    protected cabecalhoService: CabecalhoService,
    protected menuLateralService: MenuLateralService,
    private leftPadPipe: LeftPadPipe,
    private changeDetector: ChangeDetectorRef,
    private backgroundModuloUtils: BackgroundModuloUtils,
    private equipeTratamentoService: EquipeTratamentoService
  ) {
    super(messageService);
    sessaoService.onIdleStart.subscribe(() => this.onIdleStart());
    sessaoService.onIdleTimeLimit.subscribe((countdown: number) =>
      this.onIdleTimeLimit(countdown)
    );
    sessaoService.onIdleEnd.subscribe(() => this.onIdleStart());
    sessaoService.onTimeout.subscribe(() => this.onTimeout());
    this.backgroundModuloUtils = new BackgroundModuloUtils(this.router);
  }

  ngOnChanges(): void {
    if(!this.perfisUsuario.some(perfil => perfil.includes("VIVA"))){
      this.equipeService
          .consultarEquipes(
              this.sessaoService.getUsuario().matricula,
              PerfilUtils.isPerfilOuvSupervisor()
          )
          .subscribe(
              (equipes) => {
                this.equipes = equipes;
                this.equipes.forEach((equipeInterador) => {
                  if (
                      this.sessaoService.getEquipeUsuario() != null &&
                      this.sessaoService.getEquipeUsuario().idEquipe ==
                      equipeInterador.idGrupo
                  ) {
                    this.equipe = equipeInterador;
                    this.equipeConsoleService.emiteEquipeUsuarioOuvChanged(
                        this.obterEquipeUsuario()
                    );
                    this.setOuvPermissoesEquipe(equipeInterador.idGrupo);
                  }
                });
              },
              (error) => {
                this.messageService.addMsgDanger(
                    "Ocorreu um erro ao pesquisar Equipes do Usuário."
                );
              }
          );
    }

    this.alteraLabelBuscaRapida()

    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public ngOnInit() {
    this.carregarItensBuscaPor();

    jQuery(".modal-idle").on("shown.bs.modal", function () {
      jQuery("#btnIdleSim").focus();
    });

    jQuery(".modal-logout").on("shown.bs.modal", function () {
      jQuery("#btnLogoutNao").focus();
    });

    // Fixar Identificador de Área no Topo
    jQuery(window).scroll(function () {
      if (jQuery(this).scrollTop() > 44) {
        jQuery(".id_cab_fixo").addClass("fixar_cab");
      } else {
        jQuery(".id_cab_fixo").removeClass("fixar_cab");
      }
    });

    if (this.apresentaComboEquipe()) {
      this.equipeService
          .consultarEquipes(
              this.sessaoService.getUsuario().matricula,
              PerfilUtils.isPerfilOuvSupervisor()
          )
          .subscribe(
              (equipes) => {
                this.equipes = equipes;
                this.equipes.forEach((equipeInterador) => {
                  if (
                      this.sessaoService.getEquipeUsuario() != null &&
                      this.sessaoService.getEquipeUsuario().idEquipe ==
                      equipeInterador.idGrupo
                  ) {
                    this.equipe = equipeInterador;
                    this.equipeConsoleService.emiteEquipeUsuarioOuvChanged(
                        this.obterEquipeUsuario()
                    );
                    this.setOuvPermissoesEquipe(equipeInterador.idGrupo);
                  }
                });
              },
              (error) => {
                this.messageService.addMsgDanger(
                    "Ocorreu um erro ao pesquisar Equipes do Usuário."
                );
              }
          );
    }

    this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          this.isEquipeOuvEditavel = this.rotasConsoleOuv.some(
              (rota) => rota === event.url
          );
        });

    this.cabecalhoService.getCabecalho()
        .subscribe((_) => {
          if(this.apresentaComboEquipe()) {
            this.ngOnChanges();
        }
    });
  }

  ngOnDestroy() {
    // Realiza o unsubscribe das Subscriptions realizadas.
    this.cabecalhoService.unsubscribe();
  }

  public apresentaComboEquipe(): boolean {
    return PerfilUtils.isPerfilOuvOperador() ||
      PerfilUtils.isPerfilOuvSupervisor() ||
      PerfilUtils.isPerfilOuvCuradoria() ||
      PerfilUtils.isPerfilOuvEstrategico() ||
      PerfilUtils.isPerfilOuvEstagMenor();
  }

  public getValorPesquisaFormatado(): number {
    if (this.valorPesquisa) {
      return Number(this.somenteNumeros(this.valorPesquisa).trim());
    }
  }

  public getTpOcorrencia(): void {
    if (this.valorPesquisa) {
      if (this.valorPesquisa.length > 6) {
        this.buscarPorOcorrenciaExterna();
      } else {
        this.buscarPorOcorrenciaExternaLegado();
      }
    } else {
      this.messageService.addMsgDanger("Insira algum valor no campo de busca.");
    }
  }

  public isOcorrenciaLegado(id: string): boolean {
    let novoId = id + "";
    if (novoId.length > 8) return false;

    return true;
  }

  selecionaItemBusca() {
    const valor = this.somenteNumeros(this.valorPesquisa);
    if (!valor) {return;}

    if(valor.length <= TamanhoEnum.TAMANHO_MATRICULA){
      this.buscaPorMatricula();
    }else if (valor.length == TamanhoEnum.TAMANHO_CNPJ) {
      this.selecionaItemPorCNPJ();
    } else if (valor.length == TamanhoEnum.TAMANHO_CPF) {
      this.selecionaItemPorCPF();
    } else if (valor.length == 12 || valor.length == 13) {
      this.buscarPorOcorrencia();
    } else {
      this.messageService.addMsgDanger("Registro não encontrado");
    }
  }

  private buscaPorMatricula() {
    if(PerfilUtils.isPerfilVivaAdmin()){
      if (this.valorPesquisa &&
          (this.regexMatricula.test(this.valorPesquisa) || this.regexMatriculaMinuscula.test(this.valorPesquisa))) {
          this.buscarPorHistoricoOcorrenciaInterna();
      } else {
        this.messageService.addMsgWarning("Digite a matrícula no formato c000000.");
      }
    }
  }

  private alteraLabelBuscaRapida() {
    if(PerfilUtils.isPerfilVivaAdmin()){
      this.placeholderBuscaRapida = "Insira o nº da ocorrência, CPF, CNPJ ou matrícula"
    }
  }


  private selecionaItemPorCNPJ(): void {
    let cnpj = this.getValorPesquisaFormatado();
    if (!isNaN(cnpj)) {
      if (cnpj.toString().length <= 11) {
        this.messageService.addMsgDanger("Insira o valor de busca corretamente.");
      } else {
        this.consultarPaginado();
      }
    }
  }

  private selecionaItemPorCPF(): void {
    let cpf = this.getValorPesquisaFormatado();
    if (!isNaN(cpf)) {
      if (cpf.toString().length > 11) {
        this.messageService.addMsgDanger("Insira o valor de busca corretamente.");
      } else {
        this.consultarPaginado();
      }
    }
  }

  carregarItensBuscaPor() {
    this.itemsBusca = [
      {
        visible:
          PerfilUtils.isPerfilVivaAdmin() ||
          PerfilUtils.isPerfilSisouVivaSoli() ||
          PerfilUtils.isPerfilOuvCuradoria() ||
          PerfilUtils.isPerfilOuvEstrategico() ||
          PerfilUtils.isPerfilOuvGestorSistema() ||
          PerfilUtils.isPerfilOuvOperador() ||
          PerfilUtils.isPerfilOuvSupervisor(),
        label: "Ocorrência Interna",
        command: () => {
          this.buscarPorOcorrenciaInterna();
        },
      },
      {
        visible:
          !PerfilUtils.isPerfilOuvEstagMenor() &&
          !PerfilUtils.isPerfilVivaAdmin() &&
          !PerfilUtils.isPerfilSisouVivaSoli(),
        label: "Ocorrência Ouvidoria/SAC",
        command: () => {
          this.getTpOcorrencia();
          this.buscarPorOcorrenciaExterna();
        },
      },
      {
        visible: PerfilUtils.isPerfilVivaAdmin(),
        label: "Histórico de Ocorrência",
        command: () => {
          this.buscarPorHistoricoOcorrenciaInterna();
        },
      },
      {
        visible:
          !PerfilUtils.isPerfilVivaAdmin() &&
          !PerfilUtils.isPerfilSisouVivaSoli(),
        label: "CPF",
        command: () => {
          if (this.valorPesquisa) {
            let cpf = this.getValorPesquisaFormatado();
            if (!isNaN(cpf)) {
              if (cpf.toString().length > 11) {
                this.messageService.addMsgDanger(
                  "Insira o valor de busca corretamente."
                );
              } else {
                this.consultarPaginado();
              }
            }
          } else {
            this.messageService.addMsgDanger(
              "Insira algum valor no campo de busca."
            );
          }
        },
      },
      {
        visible:
          !PerfilUtils.isPerfilVivaAdmin() &&
          !PerfilUtils.isPerfilSisouVivaSoli(),
        label: "CNPJ",
        command: () => {
          if (this.valorPesquisa) {
            let cnpj = this.getValorPesquisaFormatado();
            if (!isNaN(cnpj)) {
              if (cnpj.toString().length <= 11) {
                this.messageService.addMsgDanger(
                  "Insira o valor de busca corretamente."
                );
              } else {
                this.consultarPaginado();
              }
            }
          } else {
            this.messageService.addMsgDanger(
              "Insira algum valor no campo de busca."
            );
          }
        },
      },
    ];
  }

  private buscarPorOcorrencia() {
    if (this.valorPesquisa) {
      let numeroOcorrencia = this.getValorPesquisaFormatado();

      if (!isNaN(numeroOcorrencia)) {
        this.consultaOcorrencia(numeroOcorrencia);
      } else {
        this.messageService.addMsgDanger("Insira o valor corretamente.");
      }
    } else {
      this.messageService.addMsgDanger("Insira algum valor no campo de busca.");
    }
  }

  private consultaOcorrencia(numeroOcorrencia: number): void {
    this.ocorrenciaExternaService.consultarPorNumeroOcorrencia(numeroOcorrencia)
    .subscribe((ocorrenciaExterna: OcorrenciaExterna) => {
      if (ocorrenciaExterna) {
        this.sessaoService.getUsuario().idTipoOcorrencia = ocorrenciaExterna.idTipoOcorrencia;
        SessaoService.usuario.idTipoOcorrencia = ocorrenciaExterna.idTipoOcorrencia;
        this.sessaoService.gravarUsuarioStorage(this.sessaoService.getUsuario());

        if (ocorrenciaExterna.idTipoOcorrencia === EnumTipoOcorrencia.SAC) {
          localStorage.setItem("SISOU_SAC_ORIGEM_BUSCA_RAPIDA", "S");
          this.redirectOcorrenciaSAC(ocorrenciaExterna);
        } else if (ocorrenciaExterna.idTipoOcorrencia === EnumTipoOcorrencia.OUVIDORIA) {
          this.redirectOcorrencia('ocorrencia/ocorrencia-ouvidoria/visualizar-ocorrencia', ocorrenciaExterna);
        }
      } else {
        this.consultaOcorrenciasInternas(numeroOcorrencia);
      }
    });
  }

  private consultaOcorrenciasInternas(numeroOcorrencia: number): void {
    this.ocorrenciaInternaService
          .consultarPorNumeroOcorrencia(numeroOcorrencia)
          .subscribe(
          (ocorrencia) => { this.sessaoService.getUsuario().idTipoOcorrencia = EnumTipoOcorrencia.INTERNA;
            this.sessaoService.gravarUsuarioStorage( this.sessaoService.getUsuario());
            SessaoService.usuario.idTipoOcorrencia = EnumTipoOcorrencia.INTERNA;
            this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; };
            this.router.navigate(["tratar-ocorrencia/tratar-ocorrencia-interna/detalhar/", ocorrencia.id,]);
          },
          () => {
            this.messageService.addMsgDanger("Nenhum registro encontrado.");
          }
        );
  }

  public redirectOcorrencia(rota: string, ocorrenciaExterna: OcorrenciaExterna){
      this.router.navigateByUrl(`${rota}/${ocorrenciaExterna.id}`);
  }

  public buscarPorOcorrenciaInterna() {
    if (this.valorPesquisa) {
      let numeroOcorrencia = this.getValorPesquisaFormatado();
      if (!isNaN(numeroOcorrencia)) {
        this.ocorrenciaInternaService
          .consultarPorNumeroOcorrencia(numeroOcorrencia)
          .subscribe(
            (ocorrencia) => {
              this.sessaoService.getUsuario().idTipoOcorrencia =
                EnumTipoOcorrencia.INTERNA;
              this.sessaoService.gravarUsuarioStorage(
                this.sessaoService.getUsuario()
              );
              SessaoService.usuario.idTipoOcorrencia =
                EnumTipoOcorrencia.INTERNA;
              this.router.routeReuseStrategy.shouldReuseRoute = () => {
                return false;
              };
              this.router.navigate([
                "tratar-ocorrencia/tratar-ocorrencia-interna/detalhar/",
                ocorrencia.id,
              ]);
            },
            (error) => {
              this.messageService.addMsgDanger("Nenhum registro encontrado.");
            }
          );
      }
    } else {
      this.messageService.addMsgDanger("Insira algum valor no campo de busca.");
    }
  }

  public buscarPorHistoricoOcorrenciaInterna() {
    if (this.valorPesquisa) {
      if(this.usuario.matricula === this.valorPesquisa){
            this.router.navigate([
              "ocorrencia/minhas-ocorrencias/historico/",
              this.usuario.matricula, "E"],
              {relativeTo: this.route.parent})
      } else {
        if(PerfilUtils.isPerfilVivaAdmin()){
          this.router.navigate([
            "tratar-ocorrencia/tratar-ocorrencia-interna/historico/",
            this.valorPesquisa, "U"],
            {relativeTo: this.route.parent})
        }
      }
    } else {
      this.messageService.addMsgDanger("Insira algum valor no campo de busca.");
    }
  }

  consultarPaginado(): void {
    let valorPesquisa = this.getValorPesquisaFormatado();
    if (valorPesquisa !== undefined) {
      const filtro: FiltroPaginado = this.getFiltroPaginado(valorPesquisa);
      this.searchPageble(
        this.ocorrenciaOuvidoriaService.consultarOcorrenciasSolicitantePaginado(
          filtro
        )
      ).subscribe((resultado) => {
        this.itens = resultado;
        if (this.itens.length > 0) {
          this.solicitanteService
            .consultarPorCpfCnpj(valorPesquisa)
            .subscribe((solicitante) => {
              this.nomeCliente = solicitante.nome;
            });

          this.cpfCnpj = valorPesquisa;
          this.cpfCompleto = this.leftPadPipe.transform(
            String(this.cpfCnpj),
            11,
            "0"
          );
          this.displayModal = true;
        } else {
          this.messageService.addMsgDanger("Nenhum registro encontrado.");
        }
      });
    }
  }

  private buscarPorOcorrenciaExterna() {
    if (this.valorPesquisa) {
      let ocorrencia = this.getValorPesquisaFormatado();
      if (!isNaN(ocorrencia)) {
        this.consultaOcorrenciaExterna(ocorrencia);
      } else {
        this.messageService.addMsgDanger("Insira o valor corretamente.");
      }
    } else {
      this.messageService.addMsgDanger("Insira algum valor no campo de busca.");
    }
  }

  private consultaOcorrenciaExterna(ocorrencia: number): void {
    this.ocorrenciaExternaService.consultarPorNumeroOcorrencia(ocorrencia).subscribe((ocorrenciaExterna: OcorrenciaExterna) => {
      if (ocorrenciaExterna) {
        this.sessaoService.getUsuario().idTipoOcorrencia = ocorrenciaExterna.idTipoOcorrencia;
        SessaoService.usuario.idTipoOcorrencia = ocorrenciaExterna.idTipoOcorrencia;
        this.sessaoService.gravarUsuarioStorage(this.sessaoService.getUsuario());

        if (ocorrenciaExterna.idTipoOcorrencia === EnumTipoOcorrencia.SAC) {
          this.redirectOcorrenciaSAC(ocorrenciaExterna);
        } else if (ocorrenciaExterna.idTipoOcorrencia === EnumTipoOcorrencia.OUVIDORIA) {
          this.redirectOcorrencia('ocorrencia/ocorrencia-ouvidoria/visualizar-ocorrencia', ocorrenciaExterna);
        }
      } else {
        this.messageService.addMsgDanger("Nenhum registro encontrado.");
      }
    });
  }

  redirectOcorrenciaSAC(ocorrenciaExterna: OcorrenciaExterna) {
    if (ocorrenciaExterna.idSituacaoOcorrencia == SituacaoOcorrenciaEnum.ABERTA_SAC ||
        ocorrenciaExterna.idSituacaoOcorrencia == SituacaoOcorrenciaEnum.REABERTA_SAC) {
      this.redirectOcorrencia('ocorrencia/ocorrencia-sac/visualizar', ocorrenciaExterna)
    } else if (ocorrenciaExterna.idSituacaoOcorrencia == SituacaoOcorrenciaEnum.CANCELADA_SAC) {
      this.redirectOcorrencia('ocorrencia/ocorrencia-sac/resposta', ocorrenciaExterna);
    } else if (ocorrenciaExterna.idSituacaoOcorrencia == SituacaoOcorrenciaEnum.RESPONDIDA_SAC ||
              ocorrenciaExterna.idSituacaoOcorrencia == SituacaoOcorrenciaEnum.RESPONDIDA_COM_PROMESSA_SAC ||
              ocorrenciaExterna.idSituacaoOcorrencia == SituacaoOcorrenciaEnum.ENCERRADA) {
      this.redirectOcorrencia('ocorrencia/ocorrencia-sac/resposta/visualizar', ocorrenciaExterna);
    } else {
      this.redirectOcorrencia('ocorrencia/ocorrencia-sac/avaliacao', ocorrenciaExterna);
    }
  }

  //RTC - 10604258
  private buscarPorOcorrenciaExternaLegado() {
    let ocorrencia = this.getValorPesquisaFormatado();
    if (isNaN(ocorrencia)) {
      this.messageService.addMsgDanger("Insira o valor corretamente.");
    }
  }

  public mostraEscondeBarraFavoritos() {
    jQuery(".barra-favoritos").slideToggle("slow");
  }

  showModal(id: string): void {
    (<any>$(this.idHashtag(id))).modal("show");
  }

  hideModal(id: string): void {
    (<any>$(this.idHashtag(id))).modal("hide");
  }

  idHashtag(id: string): string {
    return id ? "#" + id : "";
  }

  public logout() {
    this.removeSessaoRegistreAqui();
    jQuery(".modal-idle").modal("hide");
    jQuery(".modal-logout").modal("hide");
    this.sessaoService.finalizarSessao(true);
  }

  public contestar(): void {
    this.router.navigate(["ocorrencia/contestacao"]);
  }

  public registrar(): void {
    this.removeSessaoRegistreAqui();
    this.router.navigate(["ocorrencia/registre-aqui"]);
  }

  public isExibirContontornoTipoOuvidoria(): boolean {
    if(this.backgroundModuloUtils.isOuvidoria()){
      return true
    }
    return false
  }

  public isExibirContontornoTipoSac(): boolean {
    if(this.backgroundModuloUtils.isSAC()){
      return true;
    }
    return false
  }

  public isExibirContontornoTipoInterna(): boolean {
    if(this.backgroundModuloUtils.isVivaVoz()){
      return true;
    }
    return false
  }

  selecionarEquipe(event) {
    this.equipe = event.value;
    this.equipeConsoleService.emiteEquipeUsuarioOuvChanged(
      this.obterEquipeUsuario()
    );
    this.setOuvPermissoesEquipe(this.equipe.idGrupo);
  }

  async getOuvPermissoesRegistroOcorrencia(idEquipe: number): Promise<VerificacaoEquipeAcoes> {
    return await this.equipeTratamentoService.verificaSePodeAcoes(idEquipe).toPromise();
  }

  public setOuvPermissoesEquipe(idEquipe: number) {
    this.getOuvPermissoesRegistroOcorrencia(idEquipe).then((retorno: VerificacaoEquipeAcoes) => {
          let permissoesEquipe = {
            idEquipe: idEquipe,
            podePreRegistro: retorno.podePreRegistro,
            podeResponder: retorno.podeResponder,
            podeTratamento: retorno.podeTratamento,
            podeContestarTarefa: retorno.podeContestarTarefa,
            podeContestarBacen: retorno.podeContestarBacen
          }
          sessionStorage.setItem("OUV_PERMISSAO_REGISTRO_OCORRENCIA", JSON.stringify(permissoesEquipe));
        }
    );
  }

  private obterEquipeUsuario(): EquipeUsuario {
    return <EquipeUsuario>{
      idEquipe: this.equipe.idGrupo,
      nomeEquipe: this.equipe.noGrupo,
    };
  }

  private onIdleStart() {
    this.onIdleTimeLimit();
    jQuery(".modal-idle").modal();
  }

  private onIdleTimeLimit(countdown?: number) {
    this.mensagemContinuidadeUso =
      "Você ainda está aí, deseja continuar com acesso? ";
    if (countdown <= 120) {
      this.mensagemContinuidadeUso += " [" + countdown + "]";
    }
  }

  private onIdleEnd() {
    jQuery(".modal-idle").modal("hide");
  }

  private removeSessaoRegistreAqui() {
    sessionStorage.removeItem(SessionsEnum.SISOU_REGISTREAQUI_CPFCLIENTE);
    sessionStorage.removeItem(SessionsEnum.SISOU_REGISTREAQUI_OCORRENCIAS);
    sessionStorage.removeItem(SessionsEnum.SISOU_REGISTREAQUI_GRUPO01);
    sessionStorage.removeItem(SessionsEnum.SISOU_REGISTREAQUI_GRUPO02);
  }

  private onTimeout() {
    this.onIdleEnd();
    this.sessaoService.finalizarSessao(true, true);
  }

  public acaoTrocarPerfil() {
    this.isApresentaModalPerfis = true;
  }

  public emitModalPerfis() {
    this.menuLateralService.emiteReloadMenuLateral();
    this.isApresentaModalPerfis = false;
  }

  public isApresentarBotoesCabecalho() {
    const perfisUsuario = this.sessaoService.getUsuario().perfisParaSelecao
    return (perfisUsuario.some(perfil => this.validaPerfilBloqueioBotoesCabecalho(perfil))
    )
  }

  private validaPerfilBloqueioBotoesCabecalho(perfil: string): boolean {
    if( perfil.match(PerfilModulo.SAC_OPER_N2.value) ||
        perfil.match(PerfilModulo.SAC_ESTAGIARIO.value) ||
        perfil.match(PerfilModulo.SAC_OPER_N1.value) ||
        perfil.match(PerfilModulo.OUV_ESTAGIARIOMENOR.value) ||
        perfil.match(PerfilModulo.SOU_TERCEIRIZADAGE.value) ||
        perfil.match(PerfilModulo.SOU_TERCEIRIZADAOP.value)){
            return false;
        }
    return true;
}

  // MODAL HISTORICO SOLICITANTE
  carregarOcorrencia(
    identificacao: string,
    id: number,
    tipo: string,
    cpfCnpj: number
  ) {
    if (this.isOcorrenciaLegado(identificacao)) {
      this.fechar();
      this.router.navigateByUrl(
        `/ocorrencia/ocorrencia-ouvidoria/consultar-ocorrencia-legado/${identificacao}/${cpfCnpj}`
      );
    } else {
      this.fechar();
      if (tipo === "SAC") {
        this.router.navigateByUrl(
          `/ocorrencia/ocorrencia-sac/visualizar/${id}`
        );
      } else {
        this.router.navigateByUrl(
          `/ocorrencia/ocorrencia-ouvidoria/visualizar-ocorrencia/${id}`
        );
      }
    }
  }

  fechar() {
    this.displayModal = false;
  }

  onChangeMask(event) {
    if (this.valorPesquisa.length == 14) {
      this.mask.next("00.000.000/0000-00");
    } else if (this.valorPesquisa.length == 11) {
      this.mask.next("000.000.000-00");
    } else if (this.valorPesquisa.length == 12) {
      this.mask.next("000000.000000");
    } else if (this.valorPesquisa.length == 13) {
      this.mask.next("000000.0000000");
    } else {
      this.mask.next("");
    }
  }

  limparMascara() {
    this.mask.next("");
  }

  gerarXLS(){
    let valorPesquisa = this.getValorPesquisaFormatado();
    if (valorPesquisa !== undefined) {
      const filtro: FiltroPaginado = this.getFiltroPaginado(valorPesquisa);
      this.ocorrenciaOuvidoriaService.exportarOcorrenciasSolicitanteXLS(filtro).subscribe(
        response => {
            WebUtil.downLoadFile(response);
        },
        err => {
          this.messageService.addMsgDanger("Ocorreu um erro ao gerar XLS.");
        }
      );
    }
  }
}
