import { VerificacaoEquipeAcoes } from './../../../models/administracao/parametrizacao/equipe-tratamento/verificacao-equipe-acoes.dto';
import {Injectable} from '@angular/core';
import {GrupoTratamento} from 'app/shared/models/administracao/parametrizacao/grupo-tratamento';
import {CrudHttpClientService} from 'app/arquitetura/shared/services/crud-http-client.service';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'app/shared/components/messages/message.service';
import {Observable} from 'rxjs';
import {SelectItemModel} from 'app/shared/models/select-item';
import {RelatorioComFiltro} from 'app/shared/models/relatorio-com-filtro';
import {TipoRelatorio} from 'app/arquitetura/shared/reports/tipo-relatorio';
import {FiltroPaginado} from 'app/shared/models/filtroPaginado';

@Injectable()
export class EquipeTratamentoService extends CrudHttpClientService<GrupoTratamento> {
   constructor(
    protected http: HttpClient,
    protected messageService: MessageService
  ) {
    super('administracao/parametrizacao/equipe-tratamento', http);
  }

  public incluir(grupoTratamento: GrupoTratamento): Observable<any> {
    return this.post(grupoTratamento);
  }

  public alterar(grupoTratamento: GrupoTratamento): Observable<any> {
    return this.put(grupoTratamento);
  }

  salvarGrupoAlteracao(grupoTratamento: GrupoTratamento): any {
    return this.put(grupoTratamento);
  }

  consultarSelectItensUnidades(perfis: string[]): Observable<any> {
    return this.http.post<SelectItemModel>(this.url + '/select-unidades', perfis, this.options());
  }

  	/**
	 * Gerar relatório
	 */
	gerarRelatorio(tipo: TipoRelatorio, filtro: FiltroPaginado): Observable<any> {
		let relatorioComFiltro: RelatorioComFiltro = new RelatorioComFiltro();
		relatorioComFiltro.tipo = TipoRelatorio[tipo];
		relatorioComFiltro.filtro = filtro;

		return this.http.post(this.url + '/gerar-relatorio', relatorioComFiltro, this.options(
			{ observe: 'response' as 'body', responseType: 'arraybuffer' }));
  }

  ativarInativar(grupoTratamento: GrupoTratamento) {
    return this.http.put(this.url + '/ativar-inativar', grupoTratamento, this.options());
  }

  buscaCategorizacao(id:any){
    return this.http.get(this.url + `/${id}`);
  }

  consultarCategoriaPage(filtro: FiltroPaginado): Observable<any>{
    return this.http.post(`${this.url}/consultar-paginado-equipe-categoria`, filtro, this.options());
  }

  consultaPorEquipeId(id: number): Observable<any> {
    return this.http.get(`${this.url}/consultar-por-id/${id}`);
  }

  verificaSePodeAcoes(idGrupo: number): Observable<VerificacaoEquipeAcoes> {
    return this.http.get<VerificacaoEquipeAcoes>(`${this.url}/verifica-equipe-pode-acoes/${idGrupo}`);
  }
}
